<template>
  <div class="flix-form-group" v-if="location">
    <h4 class="flix-html-h4">{{ $t('message.location') }}</h4>
    <div v-html="location" :style="{'margin-bottom': '10px'}" />
    <a v-if="googleLink" target="_blank" :href="googleLink" class="flix-btn flix-btn-xs flix-btn-default"><flixIcon id="map-marker" /> {{ $t('message.goTo', {name: 'Google-Maps'}) }}</a>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: Object
  },
  data () {
    return {
      location: false,
      googleLink: false
    }
  },
  computed: {

  },
  mounted () {
    this.getLocation()
  },
  methods: {
    getLocation () {
      var l = JSON.parse(this.data.atitle)
      if (typeof l.location === 'undefined' || !l.location) {
        return false
      }
      this.googleLink = 'https://www.google.de/maps/place/' + l.location.split('\n').join(' ').split(' ').join('+')
      this.location = l.location.split('\n').join('<br>')
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
